import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import 'jquery'
import { marked } from 'marked';
import '../css/main.scss';
import '../lightbox2/src/js/lightbox';
import '../lightbox2/src/css/lightbox.css';
import * as bootstrap from 'bootstrap';

Alpine.plugin(intersect)

const MY_API_URL = "https://lasiocampidae-america.com/api"

document.addEventListener('alpine:init', () => {

  Alpine.data('tabs', (defaultTab) => ({
    tab: defaultTab,
    title: document.querySelector("head > title").innerText,
    init() {
      if (window.location.hash !== "") {
        if (window.location.hash.match(/ADMIN-/)) {
          this.tab = "admin"
        } else {
          if (window.location.hash.match(/indeter/)) {
            Alpine.store('especes').toggleEspece('indeter')
          } else if (window.location.hash.match(/collection/)) {
            Alpine.store('especes').toggleEspece('all')
          }
          this.tab = window.location.hash.replace("#", "")
        }
        document.querySelector("head > title").innerText = this.title + " | " + window.location.hash.replace("#", "").toUpperCase()
      }
    },
    toggleTab(e) {
      if (e.target.getAttribute('href').match(/ADMIN-/)) {
        this.tab = "admin"
        Alpine.store('admin').displayEspece('NUMERO', e.target.getAttribute('href').replace("#ADMIN-", ""))
      } else {
        this.tab = e.target.getAttribute('href').replace("#", "")
      }
      document.querySelector("head > title").innerText = this.title + " | " + e.target.getAttribute('href').replace("#", "")
      return true
    },
    isActive(tab) {
      return tab === this.tab
    }
  }))

  Alpine.store('textes', {
    subtitle: [],
    accueil: [],
    collection: [],
    taxonomie: [],
    about: [],
    getTextes(keys) {
      keys.forEach(key => {
        fetch(`${MY_API_URL}/textes?name=${key}`)
          .then(r => r.json())
          .then(r => this[key] = r.map(r => marked.parse(r.Notes)))
      })
    }
  })

  Alpine.store('textes').getTextes(["subtitle", "accueil", "taxonomie", "collection", "about", "admin"])

  Alpine.store('photospub', {
    especes: {},
    hasPhoto(NUMERO) {
      if (this.especes[NUMERO] !== undefined && this.especes[NUMERO].length > 0) {
        return true
      }
      return false
    },
    getPhotosForEspece(espece){
      if(Alpine.store('photospub').especes[espece.NUMERO] === undefined || Alpine.store('photospub').especes[espece.NUMERO].length == 0){
        fetch(`${MY_API_URL}/photospub?GENRE=${espece.GENRE}&NUMERO=${espece.NUMERO}`)
        .then(re => re.json())
        .then(res => {
          Alpine.store('photospub').especes[espece.NUMERO] = res
        })
      }
    }
  })
  Alpine.store('especes', {
    data: [],
    dataFilter: [],
    genres: [],
    especes: [],
    all: [],
    indeter: [],
    payss: [],
    isFilter: false,
    isLoaded: false,
    isList: true,
    isInMyWorking(espece) {
      return false // Guy ne veut plus les repérer, je laisse au cas où
      if (espece.TYPE_ORIGINE !== undefined && espece.TYPE_ORIGINE.includes('GF')) {
        return true
      }
    },
    toggleVue(e) {
      if (e.target.classList.contains("active")) {
        return
      } else {
        this.isList = !this.isList
      }
    },
    toggleEspece(vue) {
      if (this[vue].length > 0) {
        this.data = this[vue]
        this.dataFilter = this.data
        this.genres = this.data.map(e => e.GENRE.trim()).filter((v, i, s) => s.indexOf(v) === i).sort()
        this.especes = this.data.map(e => e.ESPECE.trim()).filter((v, i, s) => s.indexOf(v) === i).sort()
        this.payss = this.data.filter(e => e.TYPE_ORIGINE !== undefined).map(e => e.TYPE_ORIGINE.trim()).filter((v, i, s) => s.indexOf(v) === i).sort()
      } else {
        this.isLoaded = false
        Alpine.store('especes').getData(vue)
      }
    },
    reinitFilter() {
      document.querySelector("#search-TYPE_ORIGINE").value = ""
      document.querySelector("#search-GENRE").value = ""
      document.querySelector("#search-ESPECE").value = ""
      document.querySelector("#hasPhoto").checked = false
      document.querySelector("#search-GENRE").dispatchEvent(new Event("change"))
      this.isFilter = false
    },
    filter() {
      this.isFilter = true
      let genreFilter = document.querySelector("#search-GENRE").value.toLowerCase()
      let especeFilter = document.querySelector("#search-ESPECE").value.toLowerCase()
      let paysFilter = document.querySelector("#search-TYPE_ORIGINE").value.toLowerCase()
      this.genres = this.data.filter(d => {
        if (d.TYPE_ORIGINE !== undefined) {
          return d.ESPECE.toLowerCase().match(especeFilter) && d.TYPE_ORIGINE.toLowerCase().match(paysFilter)
        }
        return d.ESPECE.toLowerCase().match(especeFilter)
      }).map(e => e.GENRE.trim()).filter((v, i, s) => s.indexOf(v) === i).sort()

      this.especes = this.data.filter(d => {
        if (d.TYPE_ORIGINE !== undefined) {
          return d.GENRE.toLowerCase().match(genreFilter) && d.TYPE_ORIGINE.toLowerCase().match(paysFilter)
        }
        return d.GENRE.toLowerCase().match(genreFilter)
      }).map(e => e.ESPECE.trim()).filter((v, i, s) => s.indexOf(v) === i).sort()

      this.dataFilter = this.data
      if (document.querySelector("#hasPhoto").checked) {
        this.dataFilter = this.dataFilter.filter(espece => Alpine.store('photospub').hasPhoto(espece.NUMERO))
      }
      this.dataFilter = this.dataFilter.filter(espece => {
        if (espece.TYPE_ORIGINE !== undefined) {
          return espece.GENRE.toLowerCase().match(genreFilter) && espece.TYPE_ORIGINE.toLowerCase().match(paysFilter)
        }
        return espece.GENRE.toLowerCase().match(genreFilter)
      })
      this.dataFilter = this.dataFilter.filter(espece => espece.ESPECE.toLowerCase().match(especeFilter))
    },
    getData(vue) {
      fetch(`${MY_API_URL}/${vue}`)
        .then(r => r.json())
        .then(r => {
          this[vue] = r.filter(r => r.NUMERO !== undefined && r.GENRE !== undefined && r.ESPECE !== undefined)
            .filter(r => !r.ESPECE.match(/^\s*$/))
          this.data = this[vue]
          this.dataFilter = this.data
          this.genres = this.data.map(e => e.GENRE.trim()).filter((v, i, s) => s.indexOf(v) === i).sort()
          this.especes = this.data.map(e => e.ESPECE.trim()).filter((v, i, s) => s.indexOf(v) === i).sort()
          this.payss = this.data.filter(e => e.TYPE_ORIGINE !== undefined).map(e => e.TYPE_ORIGINE.trim()).filter((v, i, s) => s.indexOf(v) === i).sort()
          this.isLoaded = true
          this.data.forEach(espece => {
            Alpine.store('photospub').especes[espece.NUMERO] = []
            Alpine.store('photospub').getPhotosForEspece(espece)
          })
        })
    }
  })

  Alpine.store('admin', {
    especes: [],
    isLoaded: false,
    genres: [],
    especesName: [],
    numeros: [],
    pays: [],
    current: {},
    nextEspece(sens) {
      if (this.especes.length === undefined) { return }

      let next = 0
      this.especes.forEach((espece, i) => {
        let regex = `^\s*${this.current.NUMERO}\s*$`
        if (espece.NUMERO.match(regex)) {
          next = i + sens
        }
      })

      if (next < 0) {
        this.current = this.especes[this.especes.length - 1]
      }
      else if (this.especes[next] === undefined) {
        this.current = this.especes[0]
      }
      else {
        this.current = this.especes[next]
      }
      Alpine.store('photospub').getPhotosForEspece(this.current)
      Alpine.store('photos').load('photos', this.current.GENRE.trim(), this.current.NUMERO.trim())
      if (window.location.hash != `#ADMIN-${this.current.NUMERO}`) {
        window.location.hash = `#ADMIN-${this.current.NUMERO}`
      }
    },
    getCommentaires() {
      let comm = ""
      if (this.current.COMMENTAIRE_PUB !== undefined) {
        comm = `Pub : ${this.current.COMMENTAIRE_PUB} ${comm}`
      }
      if (this.current.COMMENTAIRE_ESPECE !== undefined) {
        comm = `Priv : ${this.current.COMMENTAIRE_ESPECE} ${comm}`
      }
      return comm
    },
    _marked(text) {
      if (text === undefined) {
        return ""
      }
      return marked.parse(text)
    },
    displayEspece(TYPE, VALUE) {
      let especeFoundTab = this.especes.filter(e => e[TYPE].trim() == VALUE.trim())
      if (especeFoundTab.length > 0) {
        this.current = especeFoundTab[0]
        Alpine.store('photospub').getPhotosForEspece(this.current)
        Alpine.store('photos').load('photos', this.current.GENRE.trim(), this.current.NUMERO.trim())
        if (window.location.hash != `#ADMIN-${this.current.NUMERO}`) {
          window.location.hash = `#ADMIN-${this.current.NUMERO}`
        }
      }
    },
    reinitCurrent() {
      this.current = {}
      Alpine.store('photos').current = []
      window.location.hash = ""
    },
    changeEspece(e) {
      Alpine.store('admin').displayEspece(e.target.id, e.target.value)
    },
    getEspeces(token) {
      fetch(`${MY_API_URL}/admin/all`, { headers: { 'Authorization': `Bearer ${token}` } })
        .then(re => re.json())
        .then(r => {
          this.especes = r.filter(r => r.NUMERO !== undefined && r.GENRE !== undefined && r.ESPECE !== undefined).filter(r => !r.ESPECE.match(/^\s*$/))
          this.genres = this.especes.map(e => e.GENRE.trim()).filter((v, i, s) => s.indexOf(v) === i).sort()
          this.especesName = this.especes.map(e => e.ESPECE.trim()).filter((v, i, s) => s.indexOf(v) === i).sort()
          this.numeros = this.especes.map(e => e.NUMERO.trim()).filter((v, i, s) => s.indexOf(v) === i).sort()
          this.pays = this.especes.filter(e => e.TYPE_ORIGINE !== undefined).map(e => e.TYPE_ORIGINE.trim()).filter((v, i, s) => s.indexOf(v) === i).sort()
          if (this.especes.length > 0) {
            if (window.location.hash.match(/ADMIN-/)) {
              Alpine.store('admin').displayEspece('NUMERO', window.location.hash.replace("#ADMIN-", ""))
            } else {
              this.current = this.especes[0]
              Alpine.store('photos').load('photos', this.current.GENRE.trim(), this.current.NUMERO.trim())
            }
          }
          this.isLoaded = true

        })
    }
  })

  Alpine.store('auth', {
    isAuth: false,
    success: false,
    error: false,
    token: "",
    msg: "",
    init() {
      if (localStorage.getItem('admin_token')) {
        this.token = localStorage.getItem('admin_token')
        this.login()
      }
    },
    login() {
      let u = ""
      if (document.querySelector("#inputUser") && document.querySelector("#inputUser").value != "") {
        u = document.querySelector("#inputUser").value
      }
      let p = ""
      if (document.querySelector("#inputPwd") && document.querySelector("#inputPwd").value != "") {
        p = document.querySelector("#inputPwd").value
      }
      let bbody = {
        username: u,
        password: p,
        token: this.token
      }
      fetch(`${MY_API_URL}/login`, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(bbody) }).then(r => {
        this.error = !r.ok && setTimeout(() => { this.error = !this.error }, 5000)
        this.success = !this.error && setTimeout(() => { this.success = !this.success }, 5000)
        this.isAuth = !this.error
        if (!r.ok) {
          this.token = ""
        }
        return r.json()
      }).then(r => {
        this.msg = r.message
        if (r.token) {
          localStorage.setItem('admin_token', r.token)
          this.token = r.token
          Alpine.store('admin').getEspeces(this.token)
        }
      })
    }
  })

  Alpine.store('photos', {
    current: [],
    currentNum: "",
    photos: [],
    genitalia: [],
    init() {
      if (Alpine.store('admin').current && Alpine.store('admin').current.NUMERO) {
        Alpine.store('photos').load('photos', Alpine.store('admin').current.GENRE, Alpine.store('admin').current.NUMERO)
      }
    },
    load(TYPE, GENRE, NUMERO) {
      if (this.currentNum == NUMERO.trim()) {
        this.current = this[TYPE]
      } else {
        fetch(`${MY_API_URL}/admin/photos?GENRE=${GENRE.trim()}&NUMERO=${NUMERO.trim()}`, { headers: { Authorization: "Bearer " + Alpine.store('auth').token } })
          .then(re => re.json())
          .then(res => {
            Alpine.store('photos').photos = res
            if (TYPE == 'photos') {
              this.currentNum = NUMERO.trim()
              this.current = res
            }
          })
        fetch(`${MY_API_URL}/admin/genitalia?GENRE=${GENRE.trim()}&NUMERO=${NUMERO.trim()}`, { headers: { Authorization: "Bearer " + Alpine.store('auth').token } })
          .then(re => re.json())
          .then(res => {
            Alpine.store('photos').genitalia = res
            if (TYPE == 'genitalia') {
              this.currentNum = NUMERO.trim()
              this.current = res
            }
          })
      }

    }
  })
})
Alpine.start()